.Card {
  width: 60%;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  width: 300px;
  height: 60px;
}
